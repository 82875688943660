import { bindable, inject }              from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseListViewModel }             from 'base-list-view-model';
import { Downloader }                    from 'resources/services/downloader';
import { FilterFormSchema }              from 'modules/entities/installing-companies/filter-form-schema';
import { InstallingCompaniesRepository } from 'modules/entities/installing-companies/services/repository';

@inject(AppContainer, InstallingCompaniesRepository, Downloader, FilterFormSchema)
export class ListInstallingCompanies extends BaseListViewModel {

    listingId = 'entities-installing-companies-listing';

    @bindable headerTitle    = 'listing.entities.installing-companies';
    @bindable newRecordRoute = 'entities.installing-companies.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.newRecordRoute   = appContainer.authenticatedUser.can(['entities.installing-companies.manage', 'entities.installing-companies.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.installing-companies.manage',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.entities.installing-company',
            repository:      this.repository,
            show:            {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['entities.installing-companies.manage', 'entities.installing-companies.view']),
            },
            edit:            {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['entities.installing-companies.manage', 'entities.installing-companies.edit']),
            },
            destroy:         {
                action:  'entities.installing-companies.destroy',
                visible: (row) => this.appContainer.authenticatedUser.can(['entities.installing-companies.manage', 'entities.installing-companies.delete']),
            },
            actions:         [],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('entidades_instaladoras'),
                },
            ],
            buttons:         [],
            selectable:      true,
            destroySelected: this.appContainer.authenticatedUser.can(['entities.installing-companies.manage', 'entities.installing-companies.delete']),
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'entity_data.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'updater',
                    name:  'updaters.name',
                    title: 'form.field.last-modified-by',
                },
                {
                    data:            'updated_at',
                    name:            'entities.updated_at',
                    title:           'form.field.last-modified-at',
                    valueConverters: [
                        { name: 'dateTimeFormat' },
                    ],
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
