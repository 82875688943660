import { BaseRepository } from 'modules/core/services/base-repository';

export class InstallingCompaniesRepository extends BaseRepository {

    baseUrl = 'entities/installing-companies';

    /**
     * Finds a record's users
     *
     * @param id
     *
     * @returns {*}
     */
    getUsers(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}/users`);
    }

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

}
