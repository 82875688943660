import { bindable, inject }              from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseFormViewModel }             from 'base-form-view-model';
import { DialogController }              from 'aurelia-dialog';
import { InstallingCompaniesRepository } from 'modules/entities/installing-companies/services/repository';

@inject(AppContainer, DialogController, InstallingCompaniesRepository)
export class ViewInstallingCompanyDetails extends BaseFormViewModel {

    formId = 'entities.installing-companies.details.view-form';

    @bindable alert;
    @bindable settings = {
        title:   'form.field.installing-company',
        size:    'modal-sm',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     */
    constructor(appContainer, dialogController, repository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        return this.fetchData(params);
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return this.repository.find(params).then(response => this.model = response);
    }

    /**
     * Closes the modal
     */
    cancel() {
        return this.dialogController.cancel();
    }

}
